var fullShinyObj = null;

function setShinyObj(shiny) {
  fullShinyObj = shiny;
} //// 2021/03: TypeScript Conversion note
// These methods are here due to the delayed initialization of `Shiny.shinyapp`. I
// In theory, there could be multiple instances of `shinyapp`. In practice (and implementation), this is not possible and is a 1:1 coupling with `window.Shiny`.
// To avoid calls to a large Shiny object, helper methods are created to wrap around calling the fully instantiated window.Shiny value.
// TODO-barret; Why is `initShiny()` delayed? Is this to allow users to shim in some code? Why can't it be defined in the init method (maybe w/ an extra trigger call?)


function shinySetInputValue(name, value, opts) {
  fullShinyObj.setInputValue(name, value, opts);
}

function shinyShinyApp() {
  return fullShinyObj.shinyapp;
}

function setShinyUser(user) {
  fullShinyObj.user = user;
}

function shinyForgetLastInputValue(name) {
  fullShinyObj.forgetLastInputValue(name);
}

function shinyBindAll(scope) {
  fullShinyObj.bindAll(scope);
}

function shinyUnbindAll(scope) {
  var includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  fullShinyObj.unbindAll(scope, includeSelf);
}

function shinyInitializeInputs(scope) {
  fullShinyObj.initializeInputs(scope);
}

function shinyAppBindOutput(id, binding) {
  fullShinyObj.shinyapp.bindOutput(id, binding);
}

function shinyAppUnbindOutput(id, binding) {
  return fullShinyObj.shinyapp.unbindOutput(id, binding);
}

function getShinyOnCustomMessage() {
  return fullShinyObj.oncustommessage;
}

var fileInputBinding;

function getFileInputBinding() {
  return fileInputBinding;
}

function setFileInputBinding(fileInputBinding_) {
  fileInputBinding = fileInputBinding_;
}

function getShinyCreateWebsocket() {
  return fullShinyObj.createSocket;
}

export { setShinyObj, shinySetInputValue, shinyShinyApp, setShinyUser, shinyForgetLastInputValue, shinyBindAll, shinyUnbindAll, shinyInitializeInputs, shinyAppBindOutput, shinyAppUnbindOutput, getShinyOnCustomMessage, getFileInputBinding, setFileInputBinding, getShinyCreateWebsocket };