function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import $ from "jquery";
import { asArray, hasOwnProperty } from "../utils";
import { isIE } from "../utils/browser";
import { shinyBindAll, shinyInitializeInputs, shinyUnbindAll } from "./initedMethods";
import { sendImageSizeFns } from "./sendImageSize";
import { renderHtml as singletonsRenderHtml } from "./singletons";

function renderDependencies(dependencies) {
  if (dependencies) {
    $.each(dependencies, function (i, dep) {
      renderDependency(dep);
    });
  }
} // Render HTML in a DOM element, add dependencies, and bind Shiny
// inputs/outputs. `content` can be null, a string, or an object with
// properties 'html' and 'deps'.


function renderContent(el, content) {
  var where = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "replace";

  if (where === "replace") {
    shinyUnbindAll(el);
  }

  var html;
  var dependencies = [];

  if (content === null) {
    html = "";
  } else if (typeof content === "string") {
    html = content;
  } else if (_typeof(content) === "object") {
    html = content.html;
    dependencies = content.deps || [];
  }

  renderHtml(html, el, dependencies, where);
  var scope = el;

  if (where === "replace") {
    shinyInitializeInputs(el);
    shinyBindAll(el);
  } else {
    var $parent = $(el).parent();

    if ($parent.length > 0) {
      scope = $parent;

      if (where === "beforeBegin" || where === "afterEnd") {
        var $grandparent = $parent.parent();
        if ($grandparent.length > 0) scope = $grandparent;
      }
    }

    shinyInitializeInputs(scope);
    shinyBindAll(scope);
  }
} // Render HTML in a DOM element, inserting singletons into head as needed


function renderHtml(html, el, dependencies) {
  var where = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "replace";
  renderDependencies(dependencies);
  return singletonsRenderHtml(html, el, where);
}

var htmlDependencies = {};

function registerDependency(name, version) {
  htmlDependencies[name] = version;
} // Re-render stylesheet(s) if the dependency has specificially requested it
// and it matches an existing dependency (name and version)


function needsRestyle(dep) {
  if (!dep.restyle) {
    return false;
  }

  var names = Object.keys(htmlDependencies);
  var idx = names.indexOf(dep.name);

  if (idx === -1) {
    return false;
  }

  return htmlDependencies[names[idx]] === dep.version;
} // Client-side dependency resolution and rendering


function renderDependency(dep) {
  var restyle = needsRestyle(dep);
  if (hasOwnProperty(htmlDependencies, dep.name) && !restyle) return false;
  registerDependency(dep.name, dep.version);
  var href = dep.src.href;
  var $head = $("head").first();

  if (dep.meta && !restyle) {
    var metas = $.map(asArray(dep.meta), function (obj, idx) {
      // only one named pair is expected in obj as it's already been decomposed
      var name = Object.keys(obj)[0];
      return $("<meta>").attr("name", name).attr("content", obj[name]);
      idx;
    });
    $head.append(metas);
  }

  if (dep.stylesheet) {
    var links = $.map(asArray(dep.stylesheet), function (stylesheet) {
      return $("<link rel='stylesheet' type='text/css'>").attr("href", href + "/" + encodeURI(stylesheet));
    });

    if (!restyle) {
      $head.append(links);
    } else {
      // This inline <style> based approach works for IE11
      var refreshStyle = function refreshStyle(href, oldSheet) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", href);

        xhr.onload = function () {
          var id = "shiny_restyle_" + href.split("?restyle")[0].replace(/\W/g, "_");
          var oldStyle = $head.find("style#" + id);
          var newStyle = $("<style>").attr("id", id).html(xhr.responseText);
          $head.append(newStyle); // We can remove the old styles immediately because the new styles
          // should have been applied synchronously.

          oldStyle.remove();
          removeSheet(oldSheet);
          sendImageSizeFns.transitioned();
        };

        xhr.send();
      };

      var findSheet = function findSheet(href) {
        for (var i = 0; i < document.styleSheets.length; i++) {
          var sheet = document.styleSheets[i]; // The sheet's href is a full URL

          if (typeof sheet.href === "string" && sheet.href.indexOf(href) > -1) {
            return sheet;
          }
        }

        return null;
      }; // Removes the stylesheet from document.styleSheets, and also removes
      // the owning <link> element, if present.


      var removeSheet = function removeSheet(sheet) {
        if (!sheet) return;
        sheet.disabled = true;
        if (isIE()) sheet.cssText = "";
        $(sheet.ownerNode).remove();
      };

      $.map(links, function (link) {
        // Find any document.styleSheets that match this link's href
        // so we can remove it after bringing in the new stylesheet
        var oldSheet = findSheet(link.attr("href")); // Add a timestamp to the href to prevent caching

        var href = link.attr("href") + "?restyle=" + new Date().getTime(); // Use inline <style> approach for IE, otherwise use the more elegant
        // <link> -based approach

        if (isIE()) {
          refreshStyle(href, oldSheet);
        } else {
          link.attr("href", href); // This part is a bit tricky. The link's onload callback will be
          // invoked after the file is loaded, but it can be _before_ the
          // styles are actually applied. The amount of time it takes for the
          // style to be applied is not predictable. We need to make sure the
          // styles are applied before we send updated size/style information
          // to the server.
          //
          // We do this by adding _another_ link, with CSS content
          // base64-encoded and inlined into the href. We also add a dummy DOM
          // element that the CSS applies to. The dummy CSS includes a
          // transition, and when the `transitionend` event happens, we call
          // sendImageSizeFns.transitioned() and remove the old sheet. We also remove the
          // dummy DOM element and dummy CSS content.
          //
          // The reason this works is because (we assume) that if multiple
          // <link> tags are added, they will be applied in the same order
          // that they are loaded. This seems to be true in the browsers we
          // have tested.
          //
          // Because it is common for multiple stylesheets to arrive close
          // together, but not on exactly the same tick, we call
          // sendImageSizeFns.transitioned(), which is debounced. Otherwise, it can result in
          // the same plot being redrawn multiple times with different
          // styling.

          link.attr("onload", function () {
            var $dummyEl = $("<div>").css("transition", "0.1s all").css("position", "absolute").css("top", "-1000px").css("left", "0");
            $dummyEl.one("transitionend", function () {
              $dummyEl.remove();
              removeSheet(oldSheet);
              sendImageSizeFns.transitioned();
            });
            $(document.body).append($dummyEl); // To ensure a transition actually happens, change the inline style _after_
            // the DOM element has been added, and also use a new random color each time
            // to prevent any potential caching done by the browser

            var color = "#" + Math.floor(Math.random() * 16777215).toString(16);
            setTimeout(function () {
              return $dummyEl.css("color", color);
            }, 10);
          });
          $head.append(link);
        }
      });
    }
  }

  if (dep.script && !restyle) {
    var scriptsAttrs = asArray(dep.script);
    var scripts = $.map(scriptsAttrs, function (x) {
      var script = document.createElement("script"); // htmlDependency()'s script arg can be a character vector or a list()

      if (typeof x === "string") {
        x = {
          src: x
        };
      } // Can not destructure Object.entries into both a `const` and a `let` variable.
      // eslint-disable-next-line prefer-const


      for (var _i = 0, _Object$entries = Object.entries(x); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            attr = _Object$entries$_i[0],
            val = _Object$entries$_i[1];

        if (attr === "src") {
          val = href + "/" + encodeURI(val);
        } // If val isn't truthy (e.g., null), consider it a boolean attribute


        script.setAttribute(attr, val ? val : "");
      }

      return script;
    });
    $head.append(scripts);
  }

  if (dep.attachment && !restyle) {
    // dep.attachment might be a single string, an array, or an object.
    var attachments = dep.attachment;
    if (typeof attachments === "string") attachments = [attachments];

    if (Array.isArray(attachments)) {
      // The contract for attachments is that arrays of attachments are
      // addressed using 1-based indexes. Convert this array to an object.
      var tmp = {};
      $.each(attachments, function (index, attachment) {
        var key = index + 1 + "";
        tmp[key] = attachment;
      });
      attachments = tmp;
    }

    var attach = $.map(attachments, function (attachment, key) {
      return $("<link rel='attachment'>").attr("id", dep.name + "-" + key + "-attachment").attr("href", href + "/" + encodeURI(attachment));
    });
    $head.append(attach);
  }

  if (dep.head && !restyle) {
    var $newHead = $("<head></head>");
    $newHead.html(dep.head);
    $head.append($newHead.children());
  }

  return true;
}

export { renderDependencies, renderContent, renderHtml, registerDependency };