function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import $ from "jquery";

// Merge opts with defaults, and return a new object.
function addDefaultInputOpts(opts) {
  var newOpts = $.extend({
    priority: "immediate",
    binding: null,
    el: null
  }, opts);

  if (newOpts && typeof newOpts.priority !== "undefined") {
    switch (newOpts.priority) {
      case "deferred":
      case "immediate":
      case "event":
        break;

      default:
        throw new Error("Unexpected input value mode: '" + newOpts.priority + "'");
    }
  }

  return newOpts;
}

var InputValidateDecorator = function InputValidateDecorator(target) {
  _classCallCheck(this, InputValidateDecorator);

  _defineProperty(this, "target", void 0);

  _defineProperty(this, "setInput", function (nameType, value, opts) {
    if (!nameType) throw "Can't set input with empty name.";
    var newOpts = addDefaultInputOpts(opts);
    this.target.setInput(nameType, value, newOpts);
  });

  this.target = target;
};

export { InputValidateDecorator, addDefaultInputOpts };